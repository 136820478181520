import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import Toggle from "../../components/controls/Toggle"
import Collapsed from "../../components/controls/Collapsed"
import CanvasToolbar from "../../components/canvas/CanvasToolbar"


import { useState } from "react"



export default function Pages( {data}) {


return (
  <>
  <Layout>
  {isLoggedIn() ? (

<Container>
  <div>
    
    

    <div className="">
      {data.airtable.data.HeroImage
        ?
        <>
      <div className="bg-orange/50 h-[425px] rounded-lg mb-24">
      <img 
        src={data.airtable.data.HeroImage}
        alt={data.airtable.data.Name}
        className="h-[425px] mx-auto"
        />
      </div>
    </>:
    undefined
  }
      <div className="rounded-full bg-yellow px-8 py-3 w-fit mb-12"><Type type="p6" nopadding="yes">Product</Type></div>
      <Type type="h1">{data.airtable.data.Name}</Type>
      <Type type="p4">{data.airtable.data.Introduction}</Type>
      <div className="flex flex-row space-x-12 my-12">
        
        {data.airtable.data.ResourceLinks
          ?
          <>
        {data.airtable.data.ResourceLinks.map((Resources) => (
          <div>
            <a href={Resources.data.URL}>
              <button className=" py-2 px-6 border hover:border-black border-black/25 rounded-xl flex flex-row space-x-4 items-center">
                <img
                  src={Resources.data.IconURL}
                  alt={Resources.data.Name}
                  className="h-8"
                  />
                <Type type="p6" nopadding="yes">{Resources.data.Type}</Type>
              </button>
            </a>
          </div>
          ))}
      </>
      :
      undefined
    }
      </div>
    </div>
    
    
    
    <div className="flex flex-col space-y-24">

      <div className=" flex flex-row border-b-2 border-slate/50">
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">All</Type></button></div>
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">Screens</Type></button></div>
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">User Stories</Type></button></div>
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">Wireframes</Type></button></div>
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">Data</Type></button></div>
        <div className=""><button className="border-b-2 pb-4 px-6 hover:border-black border-slate/50 relative top-[2px]"><Type type="h4" nopadding="yes">Insights</Type></button></div>
        
      </div>

    

    <Collapsed title="Screens">
    {data.airtable.data.Screens
      ?
      <div className="grid grid-cols-3 gap-8">
      {data.airtable.data.Screens.map((ScreensArray) => (
        <div className="">
        <div className="w-full h-[275px] rounded-t-xl bg-grey-light overflow-hidden z-1">
        <img src="https://ik.imagekit.io/pathventures/systems/Figjam"
          alt="Figma"
          className="h-8 m-4 absolute top left z-2"
        />      
          {ScreensArray.data.ScreenshotURL
            ?
          <img src={ScreensArray.data.ScreenshotURL}
          alt={ScreensArray.data.Name}
          className="w-full object-cover"
            />
            :
            <div className="h-full w-full flex items-center justify-center text-grey"><Type type="p6" nopadding="yes">Add a screenshot</Type></div>   
            }
          </div>
          <div className="bg-yellow/10 rounded-b-lg p-4 flex flex-row space-x-12">
                   
            <Type type="p6" nopadding="yes">{ScreensArray.data.Name}</Type>
          </div>
        </div>
      ))}
      </div>
      :
      <div className="p-24 flex items-center">
        <Type type="p6" nopadding="yes">Add a screen</Type>
      </div>
    }
    </Collapsed>
    
    
    <Collapsed title="Features">
      <div className="grid grid-cols-3 gap-8">
      {data.airtable.data.Features.map((FeaturesArray) => (
        <div className="bg-blue p-8 rounded-lg">
        {FeaturesArray.data.Description
          ?
          <div>
            <Type type="h5">{FeaturesArray.data.Name}</Type>
            <Type type="p5" nopadding="yes">{FeaturesArray.data.Description}</Type>
          </div>
          :
          <Type type="h5" nopadding="yes">{FeaturesArray.data.Name}</Type>
        }
      </div>
      ))}
      </div>
    </Collapsed>
  
  <Collapsed title="Data">
  {data.airtable.data.Features.map((FeaturesDataArray) => (
    <>
    {FeaturesDataArray.data.Name}
    {/*}
    {FeaturesDataArray.data.Data
      ?
      <div className="grid grid-cols-3 gap-8">
      {FeaturesDataArray.data.Data.map((DataArray) => (
        <div className="bg-blue p-8 rounded-lg">
        <Type type="h5">{DataArray.data.Name}</Type>
        <Type type="p5" nopadding="yes">{DataArray.data.Source}</Type>
        <Type type="p5" nopadding="yes">{DataArray.data.Access}</Type>
        <Type type="p5" nopadding="yes">{DataArray.data.APIAvailability}</Type>
      </div>
      ))}
      </div>
      :
      undefined
    }
    */}
  </>
    ))}
    </Collapsed>
{/*}
  <Collapsed title="Insights">
  {data.airtable.data.Insights
    ?
      <div className="grid grid-cols-3 gap-8">
      {data.airtable.data.Insights.map((InsightsArray) => (
        <div className="bg-blue p-8 rounded-lg">
        {InsightsArray.data.Notes
          ?
          <div>
            <Type type="h5">{InsightsArray.data.Name}</Type>
            <Type type="p5" nopadding="yes">{InsightsArray.data.Notes}</Type>
          </div>
          :
          <Type type="h5">{InsightsArray.data.Name}</Type>
        }
      </div>
      ))}
      </div>
      :
      <div className="p-24 flex items-center">
        <Type type="p6" nopadding="yes">Add an insight</Type>
      </div>
    }
    </Collapsed>
    
*/}
 
      
     
    </div>
      
   

    </div>

 
</Container>    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}

  </Layout>
    </>

  )
}

export const Head = () => <title>Journey</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Products" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        Introduction
        HeroImage
        ResourceLinks {data{
          URL
          Type
          IconURL
        }}
        Screens {data{
          Name
          ScreenshotURL
        }}
        Features {data {Name
          Description
         
        }}
      }
    }
  }
`
